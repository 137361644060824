<template>
  <v-container class="mt-5">
    <v-carousel style="margin: auto;" v-model="model" cycle height="250px" class="d-md-none d-lg-none d-xl-none d-sm-flex d-xs-flex">
      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext11.jpg"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext12.jpg"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext13.jpg"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext14.jpg"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext5.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext6.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext7.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext8.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext9.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext10.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext1.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" contain src="../assets/extensions/ext2.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" contain src="../assets/extensions/ext3.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" contain src="../assets/extensions/ext4.png"></v-img>
      </v-carousel-item>
    </v-carousel>

    <v-carousel style="margin: auto;" v-model="model" cycle height="500px" class="d-none d-md-flex">
      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext11.jpg"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext12.jpg"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext13.jpg"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext14.jpg"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext5.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext6.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext7.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext8.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext9.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext10.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" src="../assets/extensions/ext1.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" contain src="../assets/extensions/ext2.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" contain src="../assets/extensions/ext3.png"></v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img style="margin: auto;" width="1000px" contain src="../assets/extensions/ext4.png"></v-img>
      </v-carousel-item>
    </v-carousel>

    <v-card class="text-center bodyText" color="rgba(0,0,0,0)" dark>
      <v-card-title>Extensions</v-card-title>
      <v-card-text>
        <p>
          Hair extensions are a really fun way to enhance or transform your current style. They are not only for length, but can be used for density and volume too. They are also a great way to create pops of color without the use of chemicals. Every look is completely customizable, and the possibilities are endless!
        </p>
        <p>
          All hair extension services are performed with the highest grade of hair available from industry leading brands. We are multi-brand and multi-method certified in Weft, Tape-in, I-tip, and K-tip methods. Each method can be combined for a "hybrid" look.. Even more possibilities!!!
        </p>
        <p>
          For less permanent options, we offer various hair wear options like Halos, Crowns, Falls, Bangs, Ponytails, and Clip-in wefts
        </p>
        <p>
          With so many options available, I’m sure we can find something to fit your lifestyle and budget while creating maximum comfort and a seamless blend!
        </p>
        <br/>
        <p>
          ** When arriving to hair extension appointments, please make sure to have clean, dry hair free of product. **
        </p>
      </v-card-text>
    </v-card>

    <div class="btnContainer">
      <v-btn class="btn" outlined @click="newGuestsClick()">New Extension Guests</v-btn>
    </div>

    <v-expansion-panels v-model="activePanelIndex" id="extPanel">
      <v-expansion-panel color="#FFFFFF" v-for="item in serviceItems" :key="item.title" class="text-center services-card">
        <v-expansion-panel-header class="breakWord">{{item.title}}</v-expansion-panel-header>
        <v-expansion-panel-content @click="contentClick()">

          <v-container class="d-flex flex-inline imageContainer" v-if="item.images">
            <v-img style="margin: auto;" max-width="30%" v-for="img in item.images" :key="img.id" contain v-bind:src="img.src"></v-img>
          </v-container>

          <div class="d-flex">
            <v-card-text @click="contentClick()">
              <p v-for="desc in item.descriptions" :key="desc.id">{{desc.text}}</p>
            </v-card-text>
          </div>

          <v-card v-for="serv in item.subServices" :key="serv.title" elevation="3" class="ma-5">
            <v-card-title class="breakWord">{{serv.title}}</v-card-title>
            <v-container class="d-flex flex-inline imageContainer">
                <v-img style="margin: auto; padding-bottom: 16px;" max-width="30%" v-for="img in serv.images" :key="img.id" contain v-bind:src="img.src"></v-img>
              </v-container>
            <div class="d-flex">
              <v-card-text @click="contentClick()">
                <div v-for="servDesc in serv.descriptions" :key="servDesc.id">
                  <p v-if="! isImage(servDesc.text)">{{servDesc.text}}</p>

                  <v-container v-if="isImage(servDesc.text)" class="d-flex flex-inline">
                    <v-img style="margin: auto;" max-width="30%" v-for="img in servDesc.text" :key="img.id" contain v-bind:src="img.src"></v-img>
                  </v-container>
                </div>
              </v-card-text>
            </div>
          </v-card>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-overlay :absolute="false" :value="overlay">
      <v-container fluid>
        <v-card class="d-md-none d-lg-none d-xl-none d-sm-flex d-xs-flex formCard">
          <div style="float: right;" @click="overlay=false"><v-icon>mdi-close-circle-outline</v-icon></div>
          <v-card-title>Online Consolutation</v-card-title>
          <v-form ref="form">
            <v-text-field v-model="formData.name" label="Name"></v-text-field>
            <v-text-field v-model="formData.email" label="Email"></v-text-field>
            <v-select v-model="formData.extType" :items="extTypes" label="What type of extensions are you interested in?"></v-select>
            <v-select v-model="formData.hairGoal" :items="hairGoal" label="What are your hair goals?"></v-select>
           <!--  <v-select v-model="formData.yesNo" :items="yesNo" label="Are you interested in a specific method?"></v-select> -->
            <v-select v-model="formData.yesExt" :items="yesNo" label="Have you ever had hair extensions before?"></v-select>
            <v-text-field v-if="formData.yesExt === 'Yes'" v-model="formData.extKind" label="If so, what kind?"></v-text-field>
            <v-text-field v-if="formData.yesExt === 'Yes'" v-model="formData.like" label="What did you like?"></v-text-field>
            <v-text-field v-if="formData.yesExt === 'Yes'" v-model="formData.dislike" label="What did you dislike?"></v-text-field>

            <v-text-field v-model="formData.challenge" label="What kind of challenges do you have with your hair now?"></v-text-field>
            <v-select v-model="formData.hairType" :items="hairType" label="Is your hair straight, wavy, curly, or extra curly?"></v-select>
            <v-select v-model="formData.hairThickness" :items="hairThickness" label="What thickness is your hair?"></v-select>
            <v-select v-model="formData.hairLoss" :items="yesNo" label="Do you have any medical issues that can cause hairloss?"></v-select>
            <v-select v-model="formData.wearUp" :items="yesNo" label="Do you wear your hair up often or for work?"></v-select>
            <v-select v-model="formData.workout" :items="yesNo" label="Do you work out frequently?"></v-select>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="submitEmailHandler()">Submit</v-btn>
            <v-btn @click="overlay=false">Cancel</v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="d-none d-md-block formCardLg">
          <div style="float: right;" @click="overlay=false"><v-icon>mdi-close-circle-outline</v-icon></div>
          <v-card-title>Online Consolutation</v-card-title>
          <v-form ref="form">
            <v-text-field v-model="formData.name" label="Name"></v-text-field>
            <v-text-field v-model="formData.email" label="Email"></v-text-field>
            <v-select v-model="formData.extType" :items="extTypes" label="What type of extensions are you interested in?"></v-select>
            <v-select v-model="formData.hairGoal" :items="hairGoal" label="What are your hair goals?"></v-select>
            <!-- <v-select v-model="formData.yesNo" :items="yesNo" label="Are you interested in a specific method?"></v-select> -->
            <v-select v-model="formData.yesExt" :items="yesNo" label="Have you ever had hair extensions before?"></v-select>
            <v-text-field v-if="formData.yesExt === 'Yes'" v-model="formData.extKind" label="If so, what kind?"></v-text-field>
            <v-text-field v-if="formData.yesExt === 'Yes'" v-model="formData.like" label="What did you like?"></v-text-field>
            <v-text-field v-if="formData.yesExt === 'Yes'" v-model="formData.dislike" label="What did you dislike?"></v-text-field>

            <v-text-field v-model="formData.challenge" label="What kind of challenges do you have with your hair now?"></v-text-field>
            <v-select v-model="formData.hairType" :items="hairType" label="Is your hair straight, wavy, curly, or extra curly?"></v-select>
            <v-select v-model="formData.hairThickness" :items="hairThickness" label="What thickness is your hair?"></v-select>
            <v-select v-model="formData.hairLoss" :items="yesNo" label="Do you have any medical issues that can cause hairloss?"></v-select>
            <v-select v-model="formData.wearUp" :items="yesNo" label="Do you wear your hair up often or for work?"></v-select>
            <v-select v-model="formData.workout" :items="yesNo" label="Do you work out frequently?"></v-select>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="submitEmailHandler()">Submit</v-btn>
            <v-btn @click="overlay=false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-overlay>

    <v-overlay :absolute="false" :value="emailOverlay">
      <v-container fluid>
        <v-card>
          <!-- <div style="float: right;" @click="emailOverlay=false"></div> -->
          <v-card-title>{{emailSubmitTitle}}</v-card-title>
          <v-card-text>{{emailConf}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="emailOverlay=false">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-overlay>

  </v-container>
</template>

<script>
  import emailjs from 'emailjs-com'

  export default {
    name: 'Extensions',

    data: () => ({
      model: 0,
      overlay: false,
      activePanelIndex: null,
      emailOverlay: false,
      emailSubmitTitle: 'Confirmation',
      emailConf: 'Thanks for contacting me!',
      formData: {extType: '', },
      extTypes: ['I-Tips', 'K-Tips', 'Wefts', 'Tape-In', 'Clip-ins and halos'],
      hairGoal: ['Length', 'Volume'],
      yesNo: ['Yes', 'No'],
      hairType: ['Straight', 'Wavy', 'Curly', 'Extra Curly'],
      hairThickness: ['Fine', 'Medium', 'Thick'],
      colors: [
        'primary',
        'secondary'
      ],
      serviceItems: [
        {
          title: 'WEFT',
          img: 'weft.jpg',
          descriptions: [
            {id: 0, text: 'This is my favorite method! Wefts provide a volume enhancing look, and do not require any heat, glue, or tape. Machine-tied or Hand-tied wefts of hair are horizontally sewn onto a beaded foundation in the hair with minimum points of attachment'}
            ],
          subServices: [
            {
              title: 'Hand-tied vs Machine-tied',
              images: [
                {id: 0, src: require('../assets/extensions/weftComparison.jpeg')},
              ],
              descriptions: [
                {id: 0, text: 'A hand-tied weft is when the hair is delicately sewn onto a single track by hand. It is very thin in comparison to a machine weft. Multiple hand-tied wefts can be stacked and brick laid on top of each other on a single row to mimic the weight and density of the natural hair. As little as two wefts can be used on a single row, while others may use three, four, or five. Different colored wefts can be layered together for a more dimensional, natural look. They can be used on all hair types, but are ideal for fine hair.'},
                {id: 1, text: 'Machine-tied wefts consist of 2-3 tracks sewn together, by machine, making them very dense. They come about 30” wide and can be cut widthwise to fit the diameter of the head, normally having enough for three rows. Machine wefts are a better option for someone with thick hair.'},
              ]
            }, {
              title: 'Foundation + Stitching',
              images: [
                {id: 0, src: require('../assets/extensions/foundationStictching1.jpg')},
                {id: 1, src: require('../assets/extensions/foundationStictching2.jpg')},
              ],
              descriptions: [
                {id: 0, text: 'A row is created by beads neatly sectioned and crimped down horizontally on the hair around the sides and back of the head. The wefts are sewn on top of the beaded row.'},
                {id: 1, text: '** The desired length and natural density of the hair will determine the amount of rows and the amount of wefts that may be needed. **'},
              ],
            }, {
              title: 'Invisible',
              images: [
                {id: 0, src: require('../assets/extensions/invisible.jpeg')},
              ],
              descriptions: [
                {id: 0, text: 'Wefts are sewn on the top and bottom of the beads so that the beads are hidden when the hair is flipped up. Perfect for finer "see through" hair and high ponytails.'},
              ],
            }, {
              title: 'Hybrid',
              images: [
                {id: 0, src: require('../assets/extensions/hybrid1.jpg')},
              ],
              descriptions: [
                {id: 0, text: 'This is a mixture of invisible and regular stitching. The wefts lay on top of the beads in the back of the head where the hair tends to be more dense. The beads become invisible closer to the ears and hairline where the hair tends to be more fine.'},
              ],
            }, {
              title: 'Maintenance',
              images: [
                {id: 0, src: require('../assets/extensions/maintenance1.jpeg')},
                {id: 1, src: require('../assets/extensions/maintenance2.jpeg')},
                {id: 2, src: require('../assets/extensions/maintenance3.jpeg')},
              ],
              descriptions: [
                {id: 0, text: '** These types of stitches are maintenanced every 5-6 weeks for half the time and half the cost. There is usually no sewing done at these appointments. The weft stays in and the beads are simply pushed back up to top while removing any natural shedding. **'},
                {id: 1, text: '** After two maintenance appointments, it is recommended to take wefts out for a re-install **'},
                {id: 2, text: ''},
              ],
            // }, {
            //   title: 'For Less Maintenance and Less Trips To The Salon',
            //   images: [
            //     {id: 0, src: require('../assets/extensions/ibeStitching.jpeg')},
            //   ],
            //   descriptions: [
            //     {id: 0, text: 'IBE® method with Ingrid: The IBE stitching  method adds additional stitches to the foundation that are very intricate and helps to eliminate the potential of slipped beads and sagging wefts making for a more durable install. On average guests should do a complete reinstall every 8-10 weeks. IBE® stitching is booked with Ingrid only. This is an invisible foundation with a specific sewing technique that Ingrid was trained to do through the IBE® company.'},
            //     {id: 1, text: [{id: 0, src: require('../assets/extensions/habit1.jpeg')}, {id: 1, src: require('../assets/extensions/habit2.jpeg')}]},
            //     {id: 2, text: 'Habit Extension Method with Jess: Habit extension method works by adding stitches to the beaded foundation, as well as the wefts. Everything is tied together. This ensures that all beads and wefts will stay intact and secure throughout the wearing and grow out process, without slipping and sagging. This method can be done on regular, invisible, and hybrid foundations. A reinstall is required every 10-12 weeks. Habit Extension Method should be booked with Jess only and is a specific certification through the Habit Extension Method company.'},
            //   ],
            }, {
              title: 'For Less Maintenance and Less Trips To The Salon',
              images: [
                {id: 3, src: require('../assets/extensions/maintenance4.jpg')},
                {id: 4, src: require('../assets/extensions/maintenance5.png')},
              ],
              descriptions: [
                {id: 0, text: 'This type of beading and stitching provides maximum flexibility and comfort. A full reinstall is required every 6, 8, or 10 weeks depending on the density of the natural hair.'},
              ],
            },
          ],
        },
        {
          title: 'TAPE-IN',
          images: [
            {id: 0, src: require('../assets/extensions/tapeInMethod.jpg')},
            {id: 1, src: require('../assets/extensions/tapeInMethod2.jpg')},
            {id: 2, src: require('../assets/extensions/tapeInMethod3.jpg')},
          ],
          descriptions: [
            {id: 0, text: 'Tapes are great because you can just kinda stick them where you need them! If you have a broken hair line or are looking to get that really blunt, slightly inverted bob, they work great as a fill in. They come in 3 different widths, with the option to have hair strands covering the tape band for even more discretion.'},
            {id: 1, text: 'The bands are hypoallergenic ranging from 1/2-1 1/2" wide, the natural hair is sandwiched between the two bands and sealed with medical grade adhesive.'},
            {id: 2, text: 'Well maintained tape-ins extensions will last for up to 3 applications.'},
            {id: 3, text: 'Tape-ins are maintenanced every 6-8 weeks. All bands are removed from the hair, and reapplied back to the root with fresh tape.'},
            {id: 4, text: '**Please arrive to maintenance appointments with clean clarified hair dried with no product in it even if you are getting color services done that day. This will ensure an easier removal process. A wash and blowout can be provided at an additional cost if needed.**'},
          ]
        },
        {
          title: 'I-TIP',
          images: [
            {id: 0, src: require('../assets/extensions/itip.jpeg')},
          ],
          descriptions: [
            {id: 0, text: 'I-tip extensions are bonded to the hair strand by strand with a clamped bead. No heat, glue, or tape is required. Each strand provides a 360 degree range of natural hair movement. They are a great option to use in combination with weft extensions to add highlights or blend ombres. They are also really nice for a full-head multi-tonal blonde on blonde application.These can also be a good fill in for haircuts. They give tons of possibilities!'},
            {id: 1, text: 'I-tips are maintenanced at 6 weeks. This is the process of pushing the bead back up to the root and removing any natural shed.'},
            {id: 2, text: 'I-tips should be removed and reinstalled every three months.'},
          ]
        },
        {
          title: 'K-TIP',
          images: [
            {id: 0, src: require('../assets/extensions/ktip.jpeg')},
          ],
          descriptions: [
            {id: 0, text: 'K-tips are keratin dipped tabs fused to the hair strand by strand and are virtually undetectable! They are beautiful for multi tonal hair color applications. Each strand provides a 360 degree range of natural hair movement. These are a great low maintenance option! Once installed they will last up to four months, and may shed naturally from the hair. You can choose to come in to fill in hair that has shed in between or wait the full four months and return for a removal and new install. K-tips are not reusable. New packs of hair will need to be ordered prior to any K-tip appointment.'},
            {id: 1, text: 'K-tips Micros are also available. They can be applied almost directly to the hair line without detection, making them a great solution for fine or thinning hair!'},
            {id: 2, text: '** Full K-tip applications can take a few hours. It is not unusual for guests to bring laptops to get some work done.**'},
          ]
        },
        {
          title: 'HAIRWEAR',
          descriptions: [
              {id: 0, text: 'Hairwear is a temporary alternative without the commitment of permanent hair extensions.'},
            ],
          subServices: [
            {
              title: 'Bangs',
              descriptions: [
                {id: 0, text: 'Sometimes you feel like having bangs, and sometimes you don\'t. For many of us, our mood changes daily. This is a great, no-commitment way to play! Bangs clip right into the fringe section and come long to be cut the way you like.'},
                ]
            },
            {
              title: 'Ponys',
              descriptions: [
                {id: 0, text: 'Because sometimes you just don\'t feel like styling your hair. Pony tails wrap around and clip in to a natural ponytail'},
              ]
            },
            {
              title: 'Crowns',
              descriptions: [
                {id: 0, text: 'Crowns are the perfect option to get more fullness on top. A perfect solution for thinning hair, or concealing stubborn gray roots! They are securely clipped to the top or crown area of the hair. I have a couple of different options for these pieces depending on hair color and style.'},
                {id: 1, text: 'prices starting at: $540+'},
              ]
            },
            {
              title: 'Halos',
              descriptions: [
                {id: 0, text: 'Halos are a weft of hair attached to a fishing wire that goes on your hair pretty much like a headband. This may sound uncomfortable, but it is actually constructed with comfort in mind! They are easily self applied and can be worn strictly for volume, or for length and volume combined. They are adjustable and come in many different lengths and colors. They can be one length or layered as well. It is also possible to wear 2 halos at once. Halos are popular for brides and bridesmaids, but can also be used for everyday wear too.'},
              ]
            },
            {
              title: 'Clip-In',
              descriptions: [
                {id: 0, text: 'Clip-ins are great to add length or volume to your hair. Perfect for weekend hair or special occasions! There are many options for clip ins and usually come in packs of 5-10 pieces in varying widths. They can be cut and labeled for customization.'},
              ]
            },
          ]
        },
        {
          title: 'CARE + INFO',
          descriptions: [
            {id: 0, text: 'All hair extensions are 100% human hair with cuticles intact and facing the same direction. Well maintained wefts and I-tips can last up to a year. Tape-ins can be reused for at least 3 applications. K-tips are the only method that are not reusable.'},
            {id: 1, text: 'When arriving at hair extension appointments, natural hair should be clean and dry.'},
            {id: 2, text: 'Arrive to Tape-in and K-tip appointments with clean, CLARIFIED hair with no conditioner. This is the only time to use clarifying shampoo on your hair as this helps for an easier removal at your appointment.'},
            {id: 3, text: 'Wefts should be washed by clipping up in sections to make sure the scalp is being cleaned and rinsed thoroughly in between rows.'},
            {id: 4, text: 'When washing Tape-ins, and K-tips, it is best to avoid conditioner at the points of attachment. This will cause slippage.'},
            {id: 5, text: 'Brush, Brush, Brush! Make sure to brush your extensions multiple times a day. Especially in the morning and then before bed. It\'s important for all of the bonds to stay separated as they grow out.'},
            {id: 6, text: 'When going to bed, all hair extensions should be dried and NEVER wet. Going to sleep with wet extensions can cause matting. Sleep with the hair all gathered into a low ponytail or 1 or 2  loose braids.'},
            {id: 7, text: 'You can\'t go wrong with a silk pillowcase or silk cap. Microfiber towels are pretty awesome too.'},
            {id: 8, text: 'For all the blonde babes, using sunscreen that isn\'t mineral based, can change the color of your extensions!'},
            {id: 9, text: 'Heat protection, for thermal styling, is a must!! Also, deep conditioning masks, serums, and oils help with longevity to protect your investment. Shampoos should be free of sulfates.'},
            {id: 10, text: 'When swimming, make sure to rinse hair with fresh water before and after swimming. Also use a leave-in conditioner. This will help to fill in the porosity.'},
            {id: 11, text: 'There are some really great product options if you head over to the “Shop” page of this website.'},
          ]
        }
      ]
    }),
    methods: {
      customColor() {
        // console.log(text)
        return 'darkslateblue--text serviceTitle';
      },
      contentClick() {
        // this.overlay = true
      },
      submitEmailHandler() {
        this.overlay = false
        try {
          emailjs.send('fringeSignUp', 'consultationForm', this.formData, 'user_HXwr8RGUbuwadEBIjrnOr')
          this.emailSubmitTitle = 'Confirmation'
          this.emailConf = 'Thanks for contacting me!'
          this.emailOverlay = true
        } catch(error) {
          console.log({error})
          this.emailSubmitTitle = 'Error'
          this.emailConf = 'Sorry there was an error. Please try again'
          this.emailOverlay = true
        }
      },
      isImage(desc) {
        return desc instanceof Array;
      },
      newGuestsClick () {
        window.open('https://form.jotform.com/232184265052148', '_blank')
      }
    },
    watch: {
      activePanelIndex: function() {
        // nav to the menu that is opened
        setTimeout(() => {
          if (this.activePanelIndex) {
            this.$vuetify.goTo('#extPanel')
          }
        }, 500);
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .bodyText {
    margin-top: 40px;
  }

  .breakWord {
    word-break: break-word;
  }

  .services-card {
    margin-top: 10px;
    padding: 10px;
  }

  .formCardLg {
    width: 600px;
    max-height: 500px;
    padding: 20px;
    overflow: auto;
  }
  .formCard {
    width: 350px;
    max-height: 500px;
    padding: 20px;
    overflow: auto;
  }

  .menuCard {
    margin-top: 10px;
  }
  .serviceTitle {
    font-size: 30px;
    margin-left: 10px;
  }

  .serviceCard {
    width: 300px;
    margin: 10px;
    /*color="#102945"*/
  }
  .servicesName {
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    color: #C18D21;
  }
  .serviceDesc {
    color: #CFCED0;
    padding: 10px;
    margin-top: 10px;
  }
  .btnContainer {
    text-align: center;
    padding: 10px 0px;
  }
  .btn {
    color: #C0B596;
    margin: 0px 8px;
  }

  .imageContainer {
    flex-wrap: wrap;
  }
</style>